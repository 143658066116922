import React, { FunctionComponent } from 'react';
import { ITimes, ITimeslot } from 'utils/models';
import { Box } from '@mui/system';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { primary } from 'theme';
import { TimePicker } from '@mui/x-date-pickers';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useMobileContext } from 'services/contexts/MobileContext';
import TimezoneOffsetDisplay from 'components/common/TimezoneOffsetDisplay';

interface IPickSlot {
  selectedDate: Date;
  bookedTimeSlots: ITimes[] | ITimeslot[] | null;
  selectedTimeslot: ITimes | ITimeslot | null;
  daysAvailability: ITimeslot | null;
  dateFromTime: Date | null;
  setDateFromTime: (date: Date | null) => void;
  dateToTime: Date | null;
  setDateToTime: (date: Date | null) => void;
  canRequestRoom: boolean;
  setIsConfirmHour: (b: boolean) => void;
  gridHeight: number;
}

const TimeSlotPicker: FunctionComponent<IPickSlot> = (props) => {
  const {
    selectedDate,
    bookedTimeSlots,
    selectedTimeslot,
    daysAvailability,
    dateFromTime,
    setDateFromTime,
    canRequestRoom,
    setIsConfirmHour,
    dateToTime,
    setDateToTime,
    gridHeight,
  } = props;

  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const dailyHours = [
    '00:00',
    '04:00',
    '08:00',
    '12:00',
    '16:00',
    '20:00',
    '24:00',
  ];

  const timepickerHeight = 300;

  function calcHeight(timeslot: ITimes | ITimeslot) {
    if (timeslot.startTime === null) return;
    if (timeslot.endTime === null) return;

    const [startHours, startMinutes] = timeslot.startTime
      .split(':')
      .map(Number);
    const [endHours, endMinutes] = timeslot.endTime.split(':').map(Number);
    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;
    const fraction = (totalEndMinutes - totalStartMinutes) / 1440;

    return fraction * timepickerHeight;
  }

  function calcTop(timeslot: ITimes | ITimeslot) {
    if (timeslot.startTime === null) return;
    const [bookedTimeSlots, minutes] = timeslot.startTime
      .split(':')
      .map(Number);
    const totalMinutes = bookedTimeSlots * 60 + minutes;
    const fraction = totalMinutes / 1440;
    return fraction * timepickerHeight;
  }

  function calcSelectedTimeslotTop(
    timeslot: ITimes | ITimeslot,
    heightArr: ITimes[] | ITimeslot[] | null,
  ) {
    if (heightArr === null) return;
    if (timeslot === undefined) return;
    let total = 0;

    for (const hour of heightArr) {
      total += calcHeight(hour)!;
    }

    // old return for relative = return calcTop(timeslot)! - total;

    const addAbsoluteHeight = isMobile ? 125 : 165;

    return calcTop(timeslot)! + addAbsoluteHeight + (isMobile ? gridHeight : 0);
  }

  function calcBgTop(
    timeslot: ITimes | ITimeslot,
    selectedTimeslot: ITimes | ITimeslot | null,
    heightArr: ITimes[] | ITimeslot[] | null,
  ) {
    if (heightArr === null) return;
    if (timeslot === undefined) return;
    let total = 0;

    for (const hour of heightArr) {
      total += calcHeight(hour)!;
    }

    const absoluteHeightAdd = isMobile ? 125 : 165;

    return calcTop(timeslot)! + absoluteHeightAdd + (isMobile ? gridHeight : 0);
  }

  function calcBookedTimeslotTop(
    heightArr: ITimes[] | ITimeslot[] | null,
    index: number,
  ) {
    if (heightArr === null) return;

    let total = 0;

    const slicedArr = heightArr.slice(0, index);

    for (const hour of slicedArr) {
      total += calcHeight(hour)!;
    }

    return calcTop(heightArr[index])! - total;
  }

  function isSmallerThanFractionOfDay(
    timeslot: ITimes | ITimeslot,
    fraction: number,
  ) {
    return calcHeight(timeslot)! / 300 < fraction;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}>
      <Box marginLeft={'2rem'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 2,
          }}>
          <Typography
            fontWeight='bold'
            fontSize={'1.375rem'}
            marginY={'2rem'}
            marginBottom={'1rem'}>
            {selectedDate.toLocaleString('en', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
            })}
          </Typography>
        </Box>
        <Grid container sx={{ height: timepickerHeight, overflow: 'hidden' }}>
          <Grid item xs={3} maxHeight={timepickerHeight}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
              }}>
              {dailyHours.map((hour) => (
                <Typography key={hour} color={primary.natural2}>
                  {hour}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            maxHeight={timepickerHeight}
            sx={{
              bgcolor: '#D5D5D5',
              borderRadius: '6px',
              overflow: 'hidden',
              zIndex: 0,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                height: '100%',
                overflow: 'hidden',
              }}>
              {bookedTimeSlots &&
                bookedTimeSlots.map((item: ITimes | ITimeslot, i: number) => (
                  <Box
                    key={`${item}, ${i}`}
                    sx={{
                      top: calcBookedTimeslotTop(bookedTimeSlots, i),
                      height: calcHeight(item),
                      width: 208,
                      bgcolor: primary.warning,
                      border: `1px solid ${primary.warningRed}`,
                      borderRadius: '6px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      flexDirection: 'column',
                      zIndex: 5,
                    }}>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: primary.warningRed,
                        fontWeight: 'bold',
                        marginBottom: 0,
                      }}>{`${item.startTime} - ${item.endTime}`}</Typography>
                    {!isSmallerThanFractionOfDay(item, 4 / 24) && (
                      <Typography
                        sx={{
                          textAlign: 'center',
                          color: primary.natural2,
                          marginTop: isSmallerThanFractionOfDay(item, 4 / 24)
                            ? -1
                            : 0,
                        }}>
                        Occupied
                      </Typography>
                    )}
                  </Box>
                ))}
              {selectedTimeslot && (
                <Box
                  sx={{
                    zIndex: 100,
                    top: calcSelectedTimeslotTop(
                      selectedTimeslot,
                      bookedTimeSlots,
                    ),
                    height: calcHeight(selectedTimeslot),
                    width: 202,
                    bgcolor: theme.palette.primary.light,
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                  }}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.primary.dark,
                    }}>
                    Your selected timeslot
                  </Typography>
                </Box>
              )}
              {daysAvailability && (
                <Box
                  sx={{
                    zIndex: 0,
                    top: calcBgTop(
                      daysAvailability,
                      selectedTimeslot,
                      bookedTimeSlots,
                    ),
                    height: calcHeight(daysAvailability),
                    width: 224,
                    bgcolor: '#f7f7f7',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                  }}></Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography variant='body2'>Displayed timezone:</Typography>
        <TimezoneOffsetDisplay />
      </Box>
      <Box
        marginLeft={'2rem'}
        display='flex'
        flexDirection='column'
        alignItems='center'>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography
            fontWeight='bold'
            color={theme.palette.primary.dark}
            marginBottom={'0.5rem'}
            textAlign='center'>
            {' '}
            Pick a start and end time
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TimePicker
              value={dateFromTime}
              onChange={setDateFromTime}
              sx={{ maxWidth: 140 }}
              ampm={false}
              closeOnSelect
            />
            <TimePicker
              value={dateToTime}
              onChange={setDateToTime}
              sx={{ maxWidth: 140 }}
              ampm={false}
              closeOnSelect
            />
          </Box>
          {!!selectedTimeslot && (
            <Typography
              sx={{
                textAlign: 'center',
                marginTop: 1,
                marginBottom: -2,
                color: canRequestRoom ? primary.natural2 : primary.warningRed,
              }}>
              {canRequestRoom
                ? 'Timeslot is available'
                : 'This timeslot is unavailable*'}
            </Typography>
          )}
        </Box>
        <Button
          onClick={() => setIsConfirmHour(true)}
          disabled={!selectedTimeslot || !canRequestRoom}
          sx={{
            bgcolor:
              !selectedTimeslot || !canRequestRoom
                ? primary.natural2
                : theme.palette.primary.dark,
            width: '100%',
            height: 50,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            marginY: 4,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}>
          <AddCircleOutlineOutlined
            sx={{ color: primary.pureWhite, fontSize: '1.625rem' }}
          />
          <Typography
            sx={{
              color: primary.pureWhite,
              paddingLeft: 1,
              fontSize: '1.125rem',
              fontWeight: 'bold',
            }}>
            Confirm timeslot
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default TimeSlotPicker;
