import {
  BOOKING_ANALYTICS,
  getData,
  MEETING_PEOPLE_ROUTE,
  MEETING_UPCOMING_ATTENDING_MEETINGS_ROUTE,
  MEETING_UPCOMING_MEETINGS_ROUTE,
} from '../../../utils/requests';
import { BookingStatsDto, IMeeting, IMentor } from '../../../utils/models';

async function fetchData<T>(route: string, ecosystemName: string): Promise<T> {
  const data = await getData(route, [
    { name: 'ecosystemName', value: ecosystemName },
  ]);
  return data;
}

export const fetchMentors = (ecosystemName: string) =>
  fetchData<IMentor[]>(MEETING_PEOPLE_ROUTE, ecosystemName);

export const fetchAnalytics = (ecosystemName: string) =>
  fetchData<BookingStatsDto[]>(BOOKING_ANALYTICS, ecosystemName);

export const fetchAttendingMeetings = (ecosystemName: string) =>
  fetchData<IMeeting[]>(MEETING_UPCOMING_MEETINGS_ROUTE, ecosystemName);

export const fetchMentorMeetings = (ecosystemName: string) =>
  fetchData<IMeeting[]>(
    MEETING_UPCOMING_ATTENDING_MEETINGS_ROUTE,
    ecosystemName,
  );
