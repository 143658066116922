import { useQuery } from '@tanstack/react-query';
import { fetchMentors } from '../../services/api/bookingApi/bookingApi';
import { useParams } from 'react-router';
import { IMentor } from '../../utils/models';

const useMentors = () => {
  const { ecosystemName } = useParams<{ ecosystemName: string }>();

  const { data, isLoading, error } = useQuery<IMentor[]>({
    queryKey: ['mentorsData', ecosystemName],
    queryFn: () => fetchMentors(ecosystemName!),
    enabled: !!ecosystemName,
  });

  return {
    isLoading,
    mentors: data,
    error,
  };
};

export default useMentors;
