import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { fetchAnalytics } from '../../services/api/bookingApi/bookingApi';

const useAnalytics = () => {
  const { ecosystemName } = useParams<{ ecosystemName: string }>();

  const { data, isLoading, error } = useQuery({
    queryKey: ['bookingAnalyticsData', ecosystemName],
    queryFn: () => fetchAnalytics(ecosystemName!),
    enabled: !!ecosystemName,
  });

  return {
    isLoading,
    analyticsData: data,
    error,
  };
};

export default useAnalytics;
