import React, { FunctionComponent } from 'react';
import { Box, Fade, Typography, useTheme } from '@mui/material';
import ManageEcosystemCard from './ManageEcosystemCard';
import { useNavigate } from 'react-router-dom';
import { getEcosystemInfo } from 'utils/storage';
import IllustrationIcon from './IllustrationIcon';
import BackgroundContainer from '../common/BackgroundContainer';

interface IManageEcosystem {}

const ManageEcosystem: FunctionComponent<IManageEcosystem> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          width: { xs: '96%', lg: '92.5rem' },
          margin: 'auto',
        }}>
        <Typography variant={'h5'} gutterBottom>
          Manage Ecosystem
        </Typography>
        <Box
          sx={{
            backgroundColor: 'rgba(250, 250, 250, 1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginY: '3rem',
          }}>
          <BackgroundContainer
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              height: { lg: '30rem' },
              gap: '2rem',
              paddingY: '4rem',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              border: 'none',
              boxShadow: theme.shadows[1],
            }}>
            {getEcosystemInfo().accessLevel.canEditAccessLevels && (
              <ManageEcosystemCard
                title={'Access control'}
                description={
                  'Define new access levels or modify existing ones.'
                }
                icon={
                  <IllustrationIcon
                    src='/images/illustrations/Access_control_grey.png'
                    alt='Access Control'
                  />
                }
                onClick={() => navigate('access')}
              />
            )}
            {getEcosystemInfo().accessLevel.canCustomizeEcosystemDisplay && (
              <ManageEcosystemCard
                title={'Appearance & tags'}
                description={'Theme colors and platform appearance options'}
                icon={
                  <IllustrationIcon
                    src='/images/illustrations/My_ecosystem_grey.png'
                    alt='Access Control'
                  />
                }
                onClick={() => navigate('theme')}
              />
            )}
            {getEcosystemInfo().accessLevel.canManageUsers && (
              <ManageEcosystemCard
                title={'Manage users'}
                description={'Invite, delete and view join requests to Acterio'}
                icon={
                  <IllustrationIcon
                    src='/images/illustrations/Manage_Users_grey.png'
                    alt='Access Control'
                  />
                }
                onClick={() => navigate('users')}
              />
            )}
            {getEcosystemInfo().accessLevel.canManageJourneys && (
              <ManageEcosystemCard
                title={'Journey templates'}
                description={
                  'Upload resources to the goals and milestones in journeys'
                }
                icon={
                  <IllustrationIcon
                    src='/images/illustrations/Journey_templates_grey.png'
                    alt='Access Control'
                  />
                }
                onClick={() => navigate('journeys')}
              />
            )}
            {getEcosystemInfo().accessLevel.canManageCompanyTemplates && (
              <ManageEcosystemCard
                title={'Customize profiles'}
                description={
                  'Create custom profiles for both company and user profiles'
                }
                icon={
                  <IllustrationIcon
                    src='/images/illustrations/Customize_profiles_grey.png'
                    alt='Access Control'
                  />
                }
                onClick={() => navigate('custom-company-profiles')}
              />
            )}
            {(getEcosystemInfo().accessLevel.canManageApplications ||
              getEcosystemInfo().accessLevel.canReviewApplicants) && (
              <ManageEcosystemCard
                title={'Application forms'}
                description={'Create custom application forms for companies'}
                icon={
                  <IllustrationIcon
                    src='/images/illustrations/Application_forms_grey.png'
                    alt='Access Control'
                  />
                }
                onClick={() => navigate('applications')}
                secIcon={
                  <IllustrationIcon
                    src='/images/placeholders/beta.png'
                    alt='Beta'
                  />
                }
              />
            )}
          </BackgroundContainer>
        </Box>
      </Box>
    </Fade>
  );
};

export default ManageEcosystem;
