import { Button, Typography, Card, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { primary } from 'theme';
import { CalendarToday, LocationOn, Visibility } from '@mui/icons-material';
import CalendarPreview from '../BookingCalendar/CalendarPreview';
import { IMentor } from 'utils/models';
import { useMobileContext } from 'services/contexts/MobileContext';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import TagDisplay from 'components/common/Tags/TagDisplay';

interface MentorProps {
  mentor: IMentor;
  id?: number | string;
}

const MentorCard: FunctionComponent<MentorProps> = (props) => {
  const { mentor, id } = props;

  const navigate = useNavigate();
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const mentorsHeight = isMobile ? 80 : 130;

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const cardStyles = {
    width: '100%',
    height: '100%',
    backgroundColor: primary.pureWhite,
    border: '1px solid #D4D4D4',
    borderRadius: '12px',
    padding: '1rem',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[1],
    },
  };

  const tags = mentor.tags;
  const location =
    mentor.location && mentor.location.length
      ? mentor.location
      : 'Not specified';

  useEffect(() => {
    if (id !== mentor.id.toString()) return;
    setShowPreview(true);
  }, []);

  function viewProfile() {
    if (ecosystemName) {
      navigate(`/ecosystem/${ecosystemName}/members/${mentor.id}`);
    }
  }

  function renderPhoto() {
    return (
      <Box
        sx={{
          height: mentorsHeight,
          width: mentorsHeight,
          cursor: 'pointer',
        }}
        onClick={viewProfile}>
        <Box
          sx={{
            width: mentorsHeight,
          }}>
          <img
            src={mentor.profilePic}
            style={{ width: '100%', height: '100%', borderRadius: '100%' }}
          />
        </Box>
      </Box>
    );
  }

  function renderAccessLevelTag() {
    return (
      <AccessLevelTagCard
        accessLevelName={mentor.accessLevelName}
        accessLevelColor={mentor.accessLevelColor}
      />
    );
  }

  function renderName() {
    return (
      <Box
        sx={{
          padding: 1.5,
        }}>
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
            fontSize: 17,
          }}>
          {mentor.fullName}
        </Typography>
        {mentor.primaryCompany && (
          <Typography fontSize={13}>{mentor.primaryCompany}</Typography>
        )}
        {mentor.role && <Typography fontSize={11}>{mentor.role}</Typography>}
      </Box>
    );
  }

  function renderLocation() {
    return (
      <Box sx={{ display: 'flex' }}>
        <LocationOn
          sx={{
            color: theme.palette.primary.main,
            fontSize: 14,
            marginRight: 0.5,
            marginTop: 0.2,
          }}
        />
        <Typography fontSize={12}>{location}</Typography>
      </Box>
    );
  }

  function renderTags() {
    if (!tags || !tags.length) {
      return null;
    }
    return (
      <Box display='flex' alignItems='center' marginBottom={1}>
        <TagDisplay tags={tags} oneRow />
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          padding: { xs: '1rem', md: '0rem' },
        }}>
        <Button
          onClick={viewProfile}
          sx={{
            bgcolor: 'inherit',
            margin: 1,
            width: '100%',
            minHeight: '32px',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          }}>
          <Visibility sx={{ color: theme.palette.primary.dark }} />
          <Typography
            sx={{ color: theme.palette.primary.dark, paddingLeft: 1 }}>
            View Profile
          </Typography>
        </Button>
        <Button
          onClick={() => setShowPreview(true)}
          sx={{
            bgcolor: theme.palette.primary.dark,
            margin: 1,
            width: '100%',
            minHeight: '32px',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}>
          <CalendarToday sx={{ color: primary.pureWhite }} />
          <Typography sx={{ color: primary.pureWhite, paddingLeft: 1 }}>
            Start Booking
          </Typography>
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Card sx={cardStyles}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            {renderAccessLevelTag()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginBottom: 2,
            }}>
            {renderPhoto()}
            {renderName()}
          </Box>
          <Box>{renderLocation()}</Box>
          <Box
            sx={{
              flex: '1',
            }}>
            {renderTags()}
          </Box>
          <Box>{renderButtons()}</Box>
        </Box>
      </Card>

      {showPreview && (
        <CalendarPreview
          accessLevelColor={mentor.accessLevelColor}
          accessLevelName={mentor.accessLevelName}
          isOpen={showPreview}
          handleClose={() => {
            setShowPreview(false);
          }}
          canBook={true}
          mentor={mentor}
        />
      )}
    </>
  );
};

export default MentorCard;
