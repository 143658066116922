import {
  AddCircleOutlineOutlined,
  Close,
  Place,
  SkipPrevious,
} from '@mui/icons-material';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { primary } from 'theme';
import { getAccount } from 'utils/storage';
import { useParams } from 'react-router';
import BookingCalendar from './BookingCalendar';
import {
  IAvailability,
  IAvailableTimeslots,
  IPeople,
  IRoom,
  ITimes,
  ITimeslot,
} from 'utils/models';
import InputField from 'components/common/InputField';
import {
  BOOKING_ROOM_BOOK_ROUTE,
  MEETING_BOOK_ROUTE,
  MEETING_USER_AVAILABILITY_ROUTE,
  getData,
  postData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { defaultAvailability } from '../Availability/Availability';
import Loading from 'components/common/Loading';
import {
  formatDateYMD,
  formatDateTime,
  isAvailableTimeslots,
  isAvailablity,
} from 'utils/utils';
import dayjs from 'dayjs';
import CommonModal from 'components/common/Modal/CommonModal';
import { useMobileContext } from 'services/contexts/MobileContext';
import TimeSlotPicker from './TimeSlotPicker';
import HoursPicker from './HoursPicker';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';

interface CalendarPreviewProps {
  isOpen: boolean;
  handleClose: () => void;
  availability?: IAvailability | IAvailableTimeslots[];
  canBook?: boolean;
  isRoomBooking?: boolean;
  mentor?: IPeople | IRoom;
  accessLevelName?: string;
  accessLevelColor?: string;
}

const CalendarPreview: FunctionComponent<CalendarPreviewProps> = (props) => {
  const { isOpen, handleClose, availability, canBook, mentor, isRoomBooking } =
    props;
  const { ecosystemName } = useParams();
  const currentAccount = getAccount();
  const { addAlert } = useContext(AlertContext);
  const isRoom = isRoomMentor(mentor);

  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const [usersAvailability, setUsersAvailability] = useState<
    IAvailability | IAvailableTimeslots[]
  >(availability || defaultAvailability);
  const [showHours, setShowHours] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [hours, setHours] = useState<ITimes[] | ITimeslot[] | null>(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState<
    ITimes | ITimeslot | null
  >(null);
  const [daysAvailability, setDaysAvailability] = useState<ITimeslot | null>(
    null,
  );
  const [isConfirmHour, setIsConfirmHour] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [topic, setTopic] = useState<string>('');
  const [info, setInfo] = useState<string>('');

  const [canRequestRoom, setCanRequestRoom] = useState<boolean>(false);

  const [dateFromTime, setDateFromTime] = useState<Date | null>(null);
  const [dateToTime, setDateToTime] = useState<Date | null>(null);

  const [monthIndex, setMonthIndex] = useState<number>(dayjs().month());
  const [monthNumber, setMonthNumber] = useState<number>(monthIndex);
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());

  const gridRef = useRef<HTMLDivElement | null>(null);
  const gridHeight = (gridRef.current && gridRef.current.clientHeight) ?? 0;

  useEffect(() => {
    setSelectedTimeslot(null);
  }, [selectedDate]);

  useEffect(() => {
    if (
      isAvailableTimeslots(usersAvailability) &&
      usersAvailability !== undefined
    ) {
      const usersAvailabilityEnforcedByTS = usersAvailability.find(
        (item: IAvailableTimeslots) =>
          item.date === formatDateYMD(selectedDate),
      )?.timeSlots;
      if (usersAvailabilityEnforcedByTS === undefined) return;
      setHours(usersAvailabilityEnforcedByTS);

      if (isRoomBooking) {
        const usersAvailabilityOnDay = usersAvailability.find(
          (item: IAvailableTimeslots) =>
            item.date === formatDateYMD(selectedDate),
        )?.daysAvailability;
        if (!usersAvailabilityOnDay) return;
        setDaysAvailability(usersAvailabilityOnDay);
      }
    } else if (isAvailablity(usersAvailability)) {
      const usersAvailabilityFromAvailability =
        usersAvailability.weeklyHours[
          selectedDate.toLocaleString('en', { weekday: 'short' }).toLowerCase()
        ].times;

      const arrayOfITimes = createTimeSlotsFromAvailability(
        usersAvailabilityFromAvailability,
        usersAvailability.booking.timeslotOption,
      );

      setHours(arrayOfITimes);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!isRoomBooking) return;
    if (dateFromTime && dateToTime) {
      const selectedTimeslot = {
        startTime: formatDateTime(dateFromTime),
        endTime: formatDateTime(dateToTime),
      };
      setSelectedTimeslot(selectedTimeslot);
    } else {
      setSelectedTimeslot(null);
    }
  }, [dateFromTime, dateToTime]);

  useEffect(() => {
    if (
      !selectedTimeslot ||
      selectedTimeslot.startTime === null ||
      selectedTimeslot.endTime === null
    )
      return;

    const isCollidingWithHours = () => {
      if (!hours || !Array.isArray(hours)) return false;
      for (const hour of hours) {
        if (hour && hour.startTime && hour.endTime) {
          const selectedStartTime = selectedTimeslot.startTime;
          const selectedEndTime = selectedTimeslot.endTime;

          if (
            selectedStartTime! <= hour.startTime &&
            selectedEndTime! >= hour.endTime
          ) {
            return true;
          }
          if (
            selectedStartTime! >= hour.startTime &&
            selectedStartTime! < hour.endTime
          ) {
            return true;
          }
          if (
            selectedEndTime! > hour.startTime &&
            selectedEndTime! <= hour.endTime
          ) {
            return true;
          }
        }
      }
      return false;
    };

    const isWithingDaysAvailability = () => {
      if (
        !daysAvailability ||
        !selectedTimeslot.startTime ||
        !selectedTimeslot.endTime
      )
        return false;
      const selectedStartTime = selectedTimeslot.startTime;
      const selectedEndTime = selectedTimeslot.endTime;

      if (
        selectedStartTime! >= daysAvailability.startTime! &&
        selectedEndTime! <= daysAvailability.endTime!
      ) {
        return true;
      }

      return false;
    };

    const canRequestBook =
      !isCollidingWithHours() &&
      isWithingDaysAvailability() &&
      (dateFromTime && dateToTime
        ? new Date(dateFromTime) < new Date(dateToTime)
        : false);

    setCanRequestRoom(canRequestBook);
  }, [selectedTimeslot, hours, daysAvailability]);

  useEffect(() => {
    getMentorAvailability(new Date(currentYear, monthNumber, 1));
  }, [monthIndex]);

  function createTimeSlotsFromAvailability(
    originalTimes: ITimes[],
    timeslotOption: number,
  ): ITimes[] {
    const allSlots: ITimes[] = [];

    originalTimes.forEach((timeRange) => {
      if (!timeRange.startTime || !timeRange.endTime) {
        return;
      }

      let startTime = new Date(`1970-01-01T${timeRange.startTime}:00`);
      const endTime = new Date(`1970-01-01T${timeRange.endTime}:00`);

      while (startTime < endTime) {
        const nextTime = new Date(startTime.getTime() + timeslotOption * 60000);
        const slotEndTime = nextTime > endTime ? endTime : nextTime;

        allSlots.push({
          startTime: startTime.toTimeString().substring(0, 5),
          endTime: slotEndTime.toTimeString().substring(0, 5),
        });

        startTime = nextTime;
      }
    });

    return allSlots;
  }

  function isRoomMentor(mentor: IPeople | IRoom | undefined): mentor is IRoom {
    if (mentor === undefined) return false;
    return (mentor as IRoom).picture !== undefined;
  }

  function handleHourDateClick(day: Date) {
    if (
      selectedDate.toString().substring(0, 15) ===
      day.toString().substring(0, 15)
    ) {
      setShowHours((prev) => !prev);
    } else {
      setSelectedDate(day);
      setShowHours(true);
    }
  }

  function handleSelectTimeslot(timeslot: ITimes) {
    if (timeslot === null) return;
    if (selectedTimeslot?.startTime === timeslot.startTime) {
      setSelectedTimeslot(null);
    } else {
      setSelectedTimeslot(timeslot);
    }
  }

  async function getMentorAvailability(date: Date) {
    if (!mentor || !mentor.id) return;
    setIsLoading(true);
    try {
      const data = await getData(
        isRoomBooking
          ? BOOKING_ROOM_BOOK_ROUTE
          : MEETING_USER_AVAILABILITY_ROUTE,
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'userId', value: mentor && mentor.id },
          { name: 'date', value: formatDateYMD(date) },
        ],
      );

      setUsersAvailability(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function requestBookTimeslot() {
    setIsLoading(true);
    try {
      const requestBody = {
        topic: topic,
        info: info,
        date: formatDateYMD(selectedDate),
        startTime: selectedTimeslot?.startTime,
        endTime: selectedTimeslot?.endTime,
        bookedUserId: mentor?.id,
      };
      await postData(
        MEETING_BOOK_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        requestBody,
      );

      if (isRoomBooking) {
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Room Booked!',
        });
      } else {
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Meeting Requested!',
        });
      }
      handleClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function requestRoomBooking() {
    setIsLoading(true);

    try {
      const requestBody = {
        date: formatDateYMD(selectedDate),
        roomId: mentor?.id,
        startTime: selectedTimeslot?.startTime,
        endTime: selectedTimeslot?.endTime,
      };
      await postData(
        BOOKING_ROOM_BOOK_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        requestBody,
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Meeting requested!',
      });
      handleClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderMentor() {
    return (
      <Box
        sx={{
          display: 'flex',
          margin: '2rem',
          marginLeft: '1rem',
          alignItems: 'center',
          height: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '1rem',
          }}>
          <Box
            sx={{
              borderRadius: '6px',
              width: 75,
              height: 75,
              overflow: 'hidden',
            }}>
            <img
              src={
                canBook
                  ? isRoom
                    ? mentor?.picture
                    : mentor?.profilePic
                  : currentAccount.profilePicture
              }
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          {!isRoomBooking && mentor && (
            <AccessLevelTagCard
              accessLevelName={
                (canBook && !isRoom && mentor?.accessLevelName) || 'Role'
              }
              accessLevelColor={
                canBook && !isRoom ? mentor?.accessLevelColor : '#FAF8BC'
              }
            />
          )}
        </Box>
        <Box>
          <Box>
            <Typography sx={{ color: theme.palette.primary.main }} gutterBottom>
              {canBook ? '' : currentAccount.fullName}
            </Typography>
          </Box>
          <Typography
            variant='h5'
            fontSize={'1.5rem'}
            lineHeight={'2.8rem'}
            gutterBottom>
            {isConfirmHour
              ? `${
                  isRoom
                    ? mentor.title
                    : topic === ''
                    ? mentor?.fullName
                    : topic
                }  |  ${selectedTimeslot?.startTime} - ${
                  selectedTimeslot?.endTime
                }  |  ${selectedDate.toLocaleDateString('en-US', {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}`
              : `${
                  isRoom
                    ? mentor.title
                    : topic === ''
                    ? mentor?.fullName
                    : topic
                }`}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Place
              sx={{ color: theme.palette.primary.main, marginRight: '0.5rem' }}
            />
            <Typography fontWeight='medium'>
              {canBook
                ? isRoom
                  ? mentor.location
                  : mentor?.location
                : 'Platform'}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  function renderCalendar() {
    return (
      <Box marginBottom={2}>
        <BookingCalendar
          availability={usersAvailability}
          handleHourDateClick={handleHourDateClick}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          monthIndex={monthIndex}
          monthNumber={monthNumber}
          currentYear={currentYear}
          setMonthIndex={setMonthIndex}
          setCurrentYear={setCurrentYear}
          setMonthNumber={setMonthNumber}
        />
      </Box>
    );
  }

  function renderMeetingRequest() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ marginBottom: '2rem' }}>
          <InputField
            value={topic}
            placeholder={'Finance, going to market etc...'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setTopic(event.target.value)
            }
            readonly={isLoading}
            label={'Meeting Topic'}
            formLabelStyles={{
              fontSize: '1.125rem',
            }}
          />
          <Box
            sx={{ display: 'flex', flexDirection: 'row-reverse', height: 0 }}>
            <Typography
              sx={{
                color: topic.length > 40 ? 'red' : theme.palette.primary.dark,
              }}>{`${topic.length} / 40`}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: '2rem' }}>
          <InputField
            value={info}
            placeholder={
              'Share information on why you are booking this meeting...'
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setInfo(event.target.value)
            }
            multiline={true}
            rows={6}
            readonly={isLoading}
            label={'Meeting info'}
            formLabelStyles={{
              fontSize: '1.125rem',
            }}
          />
          <Box
            sx={{ display: 'flex', flexDirection: 'row-reverse', height: 0 }}>
            <Typography
              sx={{
                color: info.length > 160 ? 'red' : theme.palette.primary.dark,
              }}>{`${info.length} / 160`}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
            marginTop: '1rem',
            width: '100%',
          }}>
          <Button
            onClick={() => setIsConfirmHour(false)}
            sx={{
              height: 50,
              borderRadius: '6px',
              width: 200,
              marginRight: '1rem',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            <SkipPrevious
              sx={{ color: theme.palette.primary.dark, fontSize: '1.625rem' }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                paddingLeft: 1,
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}>
              Back
            </Typography>
          </Button>
          <Button
            onClick={requestBookTimeslot}
            sx={{
              bgcolor: theme.palette.primary.dark,
              height: 50,
              width: 297.5,
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            {isLoading ? (
              <Loading size={26} />
            ) : (
              <>
                <AddCircleOutlineOutlined
                  sx={{ color: primary.pureWhite, fontSize: '1.625rem' }}
                />
                <Typography
                  sx={{
                    color: primary.pureWhite,
                    paddingLeft: 1,
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                  }}>
                  Confirm booking
                </Typography>
              </>
            )}
          </Button>
        </Box>
      </Box>
    );
  }

  function renderRoomBookingRequest() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '6rem',
        }}>
        <Typography
          fontWeight='bold'
          fontSize={'2rem'}
          color={theme.palette.primary.dark}>
          {`Do you wish to Confirm this timeslot: ${selectedTimeslot?.startTime} - ${selectedTimeslot?.endTime}?`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
            marginTop: '1rem',
            width: '100%',
          }}>
          <Button
            onClick={() => setIsConfirmHour(false)}
            sx={{
              height: 50,
              borderRadius: '6px',
              maxWidth: 200,
              width: '100%',
              marginRight: '1rem',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            <SkipPrevious
              sx={{ color: theme.palette.primary.dark, fontSize: '1.625rem' }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                paddingLeft: 1,
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}>
              Back
            </Typography>
          </Button>
          <Button
            onClick={requestRoomBooking}
            sx={{
              bgcolor: theme.palette.primary.dark,
              height: 50,
              maxWidth: 297.5,
              width: '100%',
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            {isLoading ? (
              <Loading size={26} />
            ) : (
              <>
                <AddCircleOutlineOutlined
                  sx={{ color: primary.pureWhite, fontSize: '1.625rem' }}
                />
                <Typography
                  sx={{
                    color: primary.pureWhite,
                    paddingLeft: 1,
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                  }}>
                  Confirm booking
                </Typography>
              </>
            )}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '94%' : showHours ? 1200 : 700,
          bgcolor: '#FFFFFF',
          boxShadow: 2,
          p: '2rem',
          paddingBottom: 0,
          borderRadius: '6px',
          minHeight: '44.688rem',
          maxHeight: '95vh',
          overflow: 'auto',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#F5F5F5',
            marginX: '-2rem',
            marginTop: '-2rem',
          }}>
          <Typography
            variant='h5'
            sx={{ marginLeft: 6, marginTop: 2, marginBottom: 2 }}>
            Booking
          </Typography>
          <Button
            sx={{ marginRight: 1, marginTop: 2, marginBottom: 2 }}
            onClick={handleClose}>
            <Close sx={{ color: theme.palette.primary.dark }} />
          </Button>
        </Box>
        {!isConfirmHour ? (
          <Grid container>
            <Grid item ref={gridRef} xs={showHours && !isMobile ? 8.5 : 12}>
              <Box sx={{ bgcolor: '#FFFFFF' }}>
                {renderMentor()}
                <Divider
                  sx={{
                    width:
                      showHours && !isMobile
                        ? 'calc(100% + 2rem)'
                        : 'calc(100% + 4rem)',
                    marginLeft: '-2rem',
                    color: '#D4D4D4',
                    marginY: '2rem',
                  }}
                />
                {isLoading ? <Loading /> : renderCalendar()}
              </Box>
            </Grid>
            {showHours && (
              <Grid
                item
                sx={{
                  borderLeft: isMobile ? 0 : 1,
                  borderColor: '#D4D4D4',
                  width: '100%',
                  marginLeft: isMobile ? '-1rem' : 0,
                }}
                xs={isMobile ? 12 : 3.5}>
                {!isRoomBooking ? (
                  <HoursPicker
                    selectedDate={selectedDate}
                    hours={hours}
                    handleSelectTimeslot={handleSelectTimeslot}
                    selectedTimeslot={selectedTimeslot}
                    canBook={canBook}
                    setIsConfirmHour={setIsConfirmHour}
                  />
                ) : (
                  <TimeSlotPicker
                    selectedDate={selectedDate}
                    bookedTimeSlots={hours}
                    selectedTimeslot={selectedTimeslot}
                    daysAvailability={daysAvailability}
                    dateToTime={dateToTime}
                    setDateToTime={setDateToTime}
                    dateFromTime={dateFromTime}
                    setDateFromTime={setDateFromTime}
                    canRequestRoom={canRequestRoom}
                    setIsConfirmHour={setIsConfirmHour}
                    gridHeight={gridHeight}
                  />
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {renderMentor()}
            <Divider
              sx={{
                width: 'calc(100% + 4rem)',
                marginX: '-2rem',
                color: '#D4D4D4',
                marginY: '2rem',
              }}
            />
            {!isRoomBooking
              ? renderMeetingRequest()
              : renderRoomBookingRequest()}
          </Box>
        )}
      </Box>
    </CommonModal>
  );
};

export default CalendarPreview;
