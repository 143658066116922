import SelectDropdown, { ISelectDropdown } from '../../common/SelectDropdown';
import React, { forwardRef } from 'react';

const ForwardedSelectDropdown = forwardRef<HTMLInputElement, ISelectDropdown>(
  (props, ref) => {
    return <SelectDropdown {...props} forwardedRef={ref} />;
  },
);

ForwardedSelectDropdown.displayName = 'SelectDropdown';
export default ForwardedSelectDropdown;
