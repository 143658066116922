import { DeleteRounded, Edit, Error, ListAlt } from '@mui/icons-material';
import { Card, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import InformationModal from 'components/common/Modal/InformationModal';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { primary } from 'theme';
import { IApplicationAssessmentTemplateBase, IListButton } from 'utils/models';
import { APPLICATION_ASSESSMENT_TEMPLATE, deleteData } from 'utils/requests';
import { FlexBox } from 'utils/styledComponents';
import { formatDateEuropean } from 'utils/utils';
import { AdminMenuKeys } from '../../AdminSideMenu';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import Loading from 'components/common/Loading';
import { useApplicationMenuContext } from 'services/contexts/Applications/ApplicationMenuContext';

interface ITemplateCard {
  name: string;
  setTemplateList: Dispatch<
    SetStateAction<IApplicationAssessmentTemplateBase[]>
  >;
  id?: number;
  createdDate?: Date;
}

const TemplateCard: FunctionComponent<ITemplateCard> = (props) => {
  const { name, id, createdDate, setTemplateList } = props;

  const theme = useTheme();
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const { setSelectedAssessmentProcessId } = useAssessmentContext();

  const { setSelectedOption } = useApplicationMenuContext();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const inputDate = createdDate ? new Date(createdDate) : new Date();
  const date = formatDateEuropean(inputDate);

  const buttonList: IListButton[] = [
    {
      name: 'Edit',
      onClick: handleEdit,
      icon: Edit,
    },
    {
      name: 'Delete',
      onClick: () => {
        setIsDeleteModalOpen(true);
        setPopoverAnchorEl(null);
      },
      icon: DeleteRounded,
      textColor: primary.warningRed,
    },
  ];

  function handleEdit() {
    if (!id) return;
    setPopoverAnchorEl(null);
    setSelectedAssessmentProcessId(id);
    setSelectedOption(AdminMenuKeys.EditAssessment);
  }

  async function handleDelete() {
    if (!id) return;

    setIsLoading(true);
    try {
      const data = await deleteData(APPLICATION_ASSESSMENT_TEMPLATE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'assessmentTemplateId', value: id },
      ]);

      setIsDeleteModalOpen(false);
      setTemplateList((prev: IApplicationAssessmentTemplateBase[]) =>
        prev.filter((template) => template.id !== id),
      );
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Successfully deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderDeleteModal() {
    if (!isDeleteModalOpen) return;
    return (
      <InformationModal
        isOpen={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        primaryText='Delete'
        primaryOnClick={handleDelete}
        primaryBgColor={primary.warningRed}
        primaryBgHoverColor={primary.warningPlaceholder}
        secondaryText='Cancel'
        secondaryOnClick={() => setIsDeleteModalOpen(false)}
        headerText={'Delete assessment form'}>
        {isLoading ? (
          <Loading height={80} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: '0.75rem',
              mb: '0.5rem',
            }}>
            <Box sx={{ display: 'flex' }}>
              <Error
                sx={{
                  marginRight: '0.25rem',
                  color: primary.warningRed,
                  width: '2rem',
                }}
              />
              <Typography sx={{ mb: '1.25rem' }}>
                Are you sure you want to delete this assessment form? This
                action cannot be undone.
              </Typography>
            </Box>
            <Typography sx={{ ml: '2.25rem' }}>
              Deleting this form will remove it permanently.
            </Typography>
          </Box>
        )}
      </InformationModal>
    );
  }

  return (
    <Card
      sx={{
        border: `1px solid ${primary.natural8}`,
        height: '100%',
        width: '100%',
        borderRadius: '8px',
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[1],
        p: '0.625rem',
      }}>
      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <Typography
          sx={{
            ml: '0.75rem',
            color: primary.natural3,
            fontSize: '0.8rem',
          }}>
          {date}
        </Typography>
        <MoreOptionsOverlay
          sx={{ bgcolor: 'inherit' }}
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}>
          <MoreOptionsButtons buttonList={buttonList} />
        </MoreOptionsOverlay>
        {renderDeleteModal()}
      </FlexBox>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ListAlt
          sx={{
            fontSize: '2.5rem',
            color: theme.palette.primary.main,
            mt: '0.5rem',
            mb: '1.5rem',
          }}
        />
        <Typography
          sx={{
            fontWeight: 'bold',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            mb: '0.5rem',
          }}>
          {name}
        </Typography>
      </Box>
    </Card>
  );
};

export default TemplateCard;
