import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import React from 'react';
import { ICustomScheme, IColorScheme } from '../../../utils/models';
import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { acterioTheme, boxShadow, mainFont, white } from '../../../theme';
import { Theme, ThemeProvider } from '@mui/material';

export const defaultSchemes: IColorScheme[] = [
  {
    name: 'Acterio',
    dark: '#13253E',
    main: '#138CC9',
    light: '#D0EDFB',
    contrastText: '#EBF1F9',
    isCustom: false,
  },
  {
    name: 'Azure Majesty',
    dark: '#1E133E',
    main: '#3B13AC',
    light: '#E3DBFA',
    contrastText: '#EFEBF9',
    isCustom: false,
  },
  {
    name: 'Orchid Dreams',
    dark: '#33133E',
    main: '#8112AA',
    light: '#EFD0FB',
    contrastText: '#F5EBF9',
    isCustom: false,
  },
  {
    name: 'Stormy Charcoal',
    dark: '#292829',
    main: '#6D6E6F',
    light: '#E6E5E6',
    contrastText: '#F2F2F2',
    isCustom: false,
  },
  {
    name: 'Cyan Oasis',
    dark: '#133E3B',
    main: '#11A198',
    light: '#D0FBF8',
    contrastText: '#EBF9F8',
    isCustom: false,
  },
  {
    name: 'Mossy Meadow',
    dark: '#133E31',
    main: '#16B988',
    light: '#D0FBEE',
    contrastText: '#EBF9F5',
    isCustom: false,
  },
  {
    name: 'Enchanted Forest',
    dark: '#10462A',
    main: '#09A456',
    light: '#D0FBE5',
    contrastText: '#EBF9F2',
    isCustom: false,
  },
  {
    name: 'Petal Pink',
    dark: '#3E1332',
    main: '#C91396',
    light: '#FBD0EF',
    contrastText: '#F9EBF5',
    isCustom: false,
  },
  {
    name: 'Raspberry Riot',
    dark: '#3E1323',
    main: '#C91354',
    light: '#FBD0E0',
    contrastText: '#F9EBF0',
    isCustom: false,
  },
  {
    name: 'Amber Blaze',
    dark: '#461D0B',
    main: '#DC7500',
    light: '#FFE7CC',
    contrastText: '#FCF3E8',
    isCustom: false,
  },
];

interface CustomThemeContextType {
  theme: Theme;
  currentTheme?: ICustomScheme | IColorScheme;
  setCurrentTheme?: (theme: ICustomScheme | IColorScheme) => void;
}

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1921,
    },
  },
  shadows: [
    'none',
    '0px 20px 27px 0px rgba(0, 0, 0, 0.05)', //Everywhere else
    '0px 1px 2px 0px rgba(21,21,21,0.08)', //All inputs and dropdowns
    ...Array(22).fill('none'),
  ] as Shadows,
  zIndex: {
    appBar: 1251,
    drawer: 1250,
  },
  palette: {
    background: {
      paper: white,
      default: white,
    },
  },
  shape: {
    borderRadius: 6,
  },
  mixins: {
    toolbar: {
      height: '4.375rem',
      justifyContent: 'space-between',
      padding: '0 3.5rem !important',
      boxShadow,
    },
  },
  typography: {
    fontFamily: [mainFont].join(','),
    h1: {
      fontSize: '3rem',
      fontFamily: mainFont,
      fontWeight: 600,
      color: acterioTheme.mainBlackFont,
    },
    h2: {
      fontSize: '2.5rem',
      fontFamily: mainFont,
      fontWeight: 600,
      color: acterioTheme.mainBlackFont,
    },
    h3: {
      fontSize: '1.875rem',
      fontFamily: mainFont,
      fontWeight: 600,
      color: acterioTheme.mainBlackFont,
    },
    h4: {
      fontSize: '1.563rem',
      fontFamily: mainFont,
      fontWeight: 600,
      color: acterioTheme.mainBlackFont,
    },
    h5: {
      fontSize: '1.25rem',
      fontFamily: mainFont,
      fontWeight: 600,
      color: acterioTheme.mainBlackFont,
    },
    h6: {
      fontSize: '1rem',
      fontFamily: mainFont,
      fontWeight: 600,
      color: acterioTheme.mainBlackFont,
    },
    body1: {
      fontFamily: mainFont,
      fontSize: '1rem',
      fontWeight: 400,
      color: acterioTheme.mainBlackFont,
    },
    body2: {
      fontFamily: mainFont,
      fontSize: '0.875remrem',
      fontWeight: 400,
      color: acterioTheme.mainBlackFont,
    },
    button: {
      fontFamily: mainFont,
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    FlexBox: {
      styleOverrides: {
        center: {
          justifyContent: 'center',
        },
        between: {
          justifyContent: 'space-between',
        },
        start: {
          justifyContent: 'start',
        },
        end: {
          justifyContent: 'end',
        },
      },
    },
  },
});

const CustomThemeContext = createContext<CustomThemeContextType>({
  theme: defaultTheme,
});

export const useCustomTheme = () => useContext(CustomThemeContext);

interface CustomThemeProviderProps {
  children: ReactNode;
}

export const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => {
  const [currentTheme, setCurrentTheme] = useState<
    ICustomScheme | IColorScheme
  >();

  const theme = useMemo(() => {
    const activeTheme = currentTheme || defaultSchemes[0];

    return createTheme({
      ...defaultTheme,
      palette: {
        primary: {
          dark: activeTheme.dark,
          main: activeTheme.main,
          light: activeTheme.light,
          contrastText: activeTheme.contrastText,
        },
      },
    });
  }, [currentTheme]);

  return (
    <CustomThemeContext.Provider
      value={{
        theme,
        currentTheme,
        setCurrentTheme,
      }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};
