import { Popover, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';
import CommonButton from '../CommonButton';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface IOverlayButton {
  popoverAnchorEl: HTMLElement | null;
  setPopoverAnchorEl: (e: HTMLElement | null) => void;
  children: React.ReactNode;
  sx?: SxProps;
  text?: string;
  boxWidth?: string | number;
  icon?: JSX.Element;
}

const ShowOptionsButton: FunctionComponent<IOverlayButton> = (props) => {
  const {
    popoverAnchorEl,
    setPopoverAnchorEl,
    children,
    sx,
    text,
    boxWidth,
    icon,
  } = props;

  const theme = useTheme();

  return (
    <>
      <CommonButton
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        sx={{
          ...sx,
        }}
        text={text ?? 'Show more...'}
        icon={icon}
        endIcon={
          !icon && !!popoverAnchorEl ? <ArrowDropUp /> : <ArrowDropDown />
        }
      />
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'left',
            width: boxWidth ?? '14rem',
            bgcolor: '#FFFFFF',
            borderRadius: '6px',
            boxShadow: theme.shadows[1],
            ...sx,
          }}>
          {children}
        </Box>
      </Popover>
    </>
  );
};

export default ShowOptionsButton;
