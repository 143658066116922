import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/system';
import { Typography, Grid, Fade } from '@mui/material';
import MentorCard from './MentorCard';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import SearchFilter from 'components/common/SearchFilter';
import useMentors from 'hooks/Booking/useMentors';
import { IMentor, ITag } from 'utils/models';
import SelectDropdown from 'components/common/SelectDropdown';
import { filterCollectionTwiceWithIntersection } from 'utils/utils';
import { ALERT_TYPES, AlertContext } from '../../Alert/AlertContext';

interface IMyBookings {
  isMentors?: boolean;
}

const MyBookings: FunctionComponent<IMyBookings> = (props) => {
  const { ecosystemName, id } = useParams();
  const { isLoading, mentors, error } = useMentors();
  const { addAlert } = useContext(AlertContext);

  const [filteredMentors, setFilteredMentors] = useState<IMentor[]>([]);

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [availableTypes, setAvailableTypes] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);

  const filterOnField = [
    'accessLevelName',
    'fullName',
    'phone',
    'location',
    'emailEcoPreferred',
    'tagsString',
  ];

  useEffect(() => {
    if (error) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message,
      });
    }
  }, [error, addAlert]);

  const [sortByChoice, setSortByChoice] = useState<string>('Name');

  const sortBy = ['Name', 'Role'];

  useEffect(() => {
    setSelectedType('');
    setSelectedTags([]);
    if (!mentors) return;
    setAvailableTypes(createSetOfTypes(mentors));
    setAvailableTags(createSetOfTags(mentors));
  }, [mentors]);

  useEffect(() => {
    if (!mentors) return;
    const deepCopy = JSON.parse(JSON.stringify(mentors));

    if (selectedTags.length < 1 || !selectedType) {
      setFilteredMentors(deepCopy);
      setAvailableTags(createSetOfTags(deepCopy));
    }

    if (selectedType || selectedTags.length > 0) {
      const updatedFilteredCommunity = filterCollectionTwiceWithIntersection(
        deepCopy,
        'accessLevelName',
        'tags',
        selectedType,
        selectedTags,
      );
      setFilteredMentors(updatedFilteredCommunity);
      setAvailableTags(createSetOfTags(updatedFilteredCommunity));
    }
  }, [selectedTags, selectedType]);

  function createSetOfTypes(communityList: IMentor[]): string[] {
    const setOfTypes = new Set<string>();

    communityList.forEach((communityItem) => {
      setOfTypes.add(communityItem.accessLevelName);
    });

    return Array.from(setOfTypes);
  }

  function createSetOfTags(mentorList: IMentor[]): string[] {
    const setOfTags = new Set<string>();
    mentorList.forEach((mentorItem) => {
      if (!mentorItem.tags) return;
      mentorItem.tags.forEach((tag: ITag) => setOfTags.add(tag.name));
    });
    return Array.from(setOfTags);
  }

  function renderMentors() {
    if (isLoading) return <Loading />;
    if (!mentors) return null;

    let hideCount = 0;
    const mentorsGrid = filteredMentors.map((mentor: any, i) => {
      const hide = mentor.filterOut === false;
      if (hide) hideCount++;

      return (
        <Fade key={mentor.id} in={true} timeout={1000}>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              display: hide ? 'none' : '',
            }}>
            <MentorCard mentor={mentor} />
          </Grid>
        </Fade>
      );
    });

    if (hideCount === filteredMentors.length && !isLoading) {
      return (
        <Typography
          variant={'h4'}
          sx={{ marginLeft: '4rem', marginTop: '2rem' }}>
          No results
        </Typography>
      );
    }

    return mentorsGrid;
  }

  // function sortMentors(mentors: IMentor[]) {
  //   if (sortByChoice === 'Name') {
  //     return [...mentors].sort((a, b) => a.fullName.localeCompare(b.fullName));
  //   } else if (sortByChoice === 'Role') {
  //     return [...mentors].sort((a, b) =>
  //       a.accessLevelName.localeCompare(b.accessLevelName),
  //     );
  //   }
  //   return mentors;
  // }

  function getOptions() {
    const options = sortBy.map((sortBy) => sortBy);
    return [...options];
  }

  function renderButtons() {
    return (
      <Box sx={{ marginBottom: '2rem', marginLeft: 1 }}>
        <SelectDropdown
          value={selectedType}
          onChange={setSelectedType}
          options={availableTypes}
          height={40}
          placeholder={'Member type'}
          sx={{
            maxWidth: '12rem',
            marginRight: '1rem',
            marginLeft: { xs: '1rem', sm: '0', md: '1rem' },
          }}
        />
        <SelectDropdown
          value={selectedTags}
          onChange={setSelectedTags}
          options={availableTags}
          placeholder='Tags'
          maxOptionsDisplayed={3}
          height={40}
          sx={{ maxWidth: '12rem' }}
          filterButton
          multiple
        />
      </Box>
    );
  }

  function renderSearchBar() {
    if (!mentors) return;

    return (
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
          flexDirection: 'row',
          alignItems: 'end',
          gap: '1.25rem',
          marginBottom: 5,
        }}>
        {!isLoading && mentors && filteredMentors.length > 0 && (
          <SearchFilter
            collection={filteredMentors}
            filterOnField={filterOnField}
            onFilter={setFilteredMentors}
          />
        )}
      </Box>
    );
  }

  // function renderSelectDropdown() {
  //   <Box sx={{ width: 100, marginLeft: 2 }}>
  //     <SelectDropdown
  //       value={sortByChoice}
  //       onChange={(value) => setSortByChoice(value)}
  //       options={getOptions()}
  //       height={38}
  //     />
  //   </Box>;
  // }

  return (
    <Box
      sx={{
        maxWidth: { xs: 'calc(100vw - 5rem)', sm: 'calc(100vw - 25rem)' },
        width: '100%',
      }}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        sx={{
          marginTop: '3rem',
          marginBottom: { xs: '3rem', sm: '0' },
        }}>
        <Box>{renderButtons()}</Box>
        <Box
          sx={{
            marginRight: '1rem',
          }}>
          {renderSearchBar()}
          {/* {renderSelectDropdown()} */}
        </Box>
      </Box>
      <Grid container spacing={4} sx={{ padding: '0 1.2rem' }}>
        {renderMentors()}
      </Grid>
    </Box>
  );
};

export default MyBookings;
