import React, { useContext, useEffect, useMemo, useState } from 'react';
import BookingAnalyticsList from './BookingAnalyticsList';
import { Typography } from '@mui/material';
import { FlexBox } from '../../../utils/styledComponents';
import SelectDropdown from '../../common/SelectDropdown';
import { ALERT_TYPES, AlertContext } from '../../Alert/AlertContext';
import Loading from '../../common/Loading';
import { TypographyGrey } from '../../Admin/Applications/ApplicationsListView';
import useAnalytics from '../../../hooks/Booking/useAnalytics';

const BookingAnalytics = () => {
  const { addAlert } = useContext(AlertContext);
  const { isLoading, analyticsData: bookingsList, error } = useAnalytics();

  const [filterByBookedBy, setFilterByBookedBy] = useState<
    string | undefined
  >();
  const [filterByBookedWith, setFilterByBookedWith] = useState<
    string | undefined
  >();
  const [filterByStatus, setFilterByStatus] = useState<
    'Accepted' | 'Pending' | 'Declined' | undefined
  >(undefined);

  const availableStatuses = ['Accepted', 'Pending', 'Declined'];

  // Combined filters function
  const filteredBookingList = useMemo(() => {
    if (!bookingsList) return [];

    return bookingsList.filter((item) => {
      const matchesBookedBy = filterByBookedBy
        ? item.bookingUser.fullName.includes(filterByBookedBy)
        : true;
      const matchesBookedWith = filterByBookedWith
        ? item.bookedUser.fullName.includes(filterByBookedWith)
        : true;

      const matchesStatus = filterByStatus
        ? filterByStatus === 'Pending'
          ? item.pending
          : filterByStatus === 'Accepted'
          ? !item.pending && item.accepted
          : !item.pending && !item.accepted
        : true;

      return matchesBookedBy && matchesBookedWith && matchesStatus;
    });
  }, [bookingsList, filterByBookedBy, filterByBookedWith, filterByStatus]);

  // options for select-dropdowns
  const availableBookedByOptions = useMemo(() => {
    return Array.from(
      new Set(bookingsList?.map((item) => item.bookingUser.fullName)),
    );
  }, [bookingsList]);

  const availableBookedWithOptions = useMemo(() => {
    return Array.from(
      new Set(bookingsList?.map((item) => item.bookedUser.fullName)),
    );
  }, [bookingsList]);

  const renderFilters = () => (
    <FlexBox>
      <SelectDropdown
        value={filterByBookedBy}
        onChange={setFilterByBookedBy}
        options={availableBookedByOptions.filter(
          (name) => name !== filterByBookedWith,
        )}
        height='2.5rem'
        placeholder='Filter booking users'
        sx={{ minWidth: '13rem', mx: '1rem' }}
      />
      <SelectDropdown
        value={filterByBookedWith}
        onChange={setFilterByBookedWith}
        options={availableBookedWithOptions.filter(
          (name) => name !== filterByBookedBy,
        )}
        height='2.5rem'
        placeholder='Filter booked users'
        sx={{ minWidth: '13rem', mx: '1rem' }}
      />
      <SelectDropdown
        value={filterByStatus}
        onChange={setFilterByStatus}
        options={availableStatuses}
        height='2.5rem'
        placeholder='Filter status'
        sx={{ minWidth: '9rem', mx: '1rem' }}
      />
      <TypographyGrey>({filteredBookingList.length})</TypographyGrey>
    </FlexBox>
  );

  useEffect(() => {
    if (error) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message,
      });
    }
  }, [error, addAlert]);

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        paddingTop: '2.5rem',
        gap: '1.875rem',
        width: '100%',
      }}>
      <Typography variant='h4'>Booking analytics</Typography>
      {renderFilters()}
      <FlexBox sx={{ width: '100%' }}>
        {isLoading ? (
          <Loading />
        ) : (
          <BookingAnalyticsList bookingList={filteredBookingList} />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default BookingAnalytics;
