import React, { FunctionComponent, useContext, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { acterioTheme, primary } from 'theme';
import CompanyContact from '../CompanyContact';
import {
  CompanyProfileFieldType,
  ICompanyField,
  IListButton,
  ITag,
} from 'utils/models';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { Box } from '@mui/system';
import { FlexBox } from 'utils/styledComponents';
import {
  ContentCopyRounded,
  DeleteRounded,
  ModeRounded,
} from '@mui/icons-material';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import { COMPANY_ROUTE, deleteData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { removeStoredCompanies } from 'utils/storage';
import { useNavigate, useParams } from 'react-router';
import InformationModal from 'components/common/Modal/InformationModal';
import { IconPicker } from 'components/common/IconPicker';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import Loading from 'components/common/Loading';

interface ICompanyHeader {
  openPdf: (options?: any | undefined) => void;
}

const CompanyHeader: FunctionComponent<ICompanyHeader> = (props) => {
  const { openPdf } = props;
  const { companyProfile, setEditProfile } = useCompanyContext();
  const { name, email, telephone, logoUrl, bannerUrl, canEditCompanyProfile } =
    companyProfile!;

  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const theme = useTheme();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const buttonList: IListButton[] = [
    {
      name: 'Edit',
      onClick: () => setEditProfile(true),
      icon: ModeRounded,
      isDisabled: !companyProfile!.canEditCompanyProfile,
    },
    {
      name: 'Generate a PDF',
      onClick: openPdf,
      icon: ContentCopyRounded,
    },
    {
      name: 'Delete',
      onClick: () => setShowDelete(true),
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
      isDisabled: !companyProfile!.canDeleteCompanyProfile,
    },
  ];

  async function deleteCompany() {
    setIsDeleteLoading(true);

    try {
      await deleteData(COMPANY_ROUTE, [
        {
          name: 'companyName',
          value: encodeURIComponent(name),
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Company was deleted',
      });

      removeStoredCompanies();
      navigate(`/ecosystem/${ecosystemName}/companies`);
    } catch (e: any) {
      setIsDeleteLoading(false);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    return null;
  }

  function renderTags(tags: ITag[]) {
    if (!tags) return null;

    return tags.map((tag) => {
      const tagToDisplay = tag.name.startsWith('#') ? tag.name : `#${tag.name}`;
      return (
        <Box
          key={tag.id}
          sx={{
            fontSize: '0.7rem',
            lineHeight: '0.7rem',
            padding: '5px 10px',
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            color: '#333333',
            textAlign: 'center',
            flexWrap: 'wrap',
            whiteSpace: 'wrap',
            mx: '0.0625rem',
          }}>
          {tagToDisplay}
        </Box>
      );
    });
  }

  function renderTagGroup() {
    const tagFields = companyProfile?.usedTags;

    if (!tagFields) return null;

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          mb: '0.8rem',
          mt: '0.2rem',
          flexWrap: 'wrap',
          whiteSpace: 'wrap',
          gap: '0.5rem',
          mr: '0.5rem',
        }}>
        {renderTags(tagFields)}
      </Box>
    );
  }

  function renderOldTagGroups() {
    if (!companyProfile?.header) return null;

    return companyProfile.header.map((field: ICompanyField) => {
      if (field.type === CompanyProfileFieldType.TAGS) {
        const tagFields = field.usedTags;

        if (!tagFields) return null;

        return (
          <Box
            key={field.id}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              marginBottom: '0.8rem',
              flexWrap: 'wrap',
              whiteSpace: 'wrap',
              gap: '0.5rem',
              mr: '0.5rem',
            }}>
            {renderTags(tagFields)}
          </Box>
        );
      }
    });
  }

  function renderCardMedia() {
    return (
      <CardMedia
        sx={{
          position: 'relative',
          height: '15rem',
          display: 'flex',
        }}>
        <CardMedia
          component='img'
          src={bannerUrl}
          alt='CompanyCard-Background'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CardMedia
            component='img'
            src={logoUrl}
            alt='CompanyCard-Logo'
            sx={{
              width: '50%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      </CardMedia>
    );
  }

  function renderCompanyTemplateTag() {
    return (
      <AccessLevelTagCard
        accessLevelName={companyProfile?.label || 'Unknown'}
        accessLevelColor={companyProfile?.color}
        height={'1.5rem'}
      />
    );
  }

  function renderDeleteModal() {
    return (
      <InformationModal
        isOpen={showDelete}
        handleClose={() => setShowDelete(false)}
        primaryText='Delete'
        primaryOnClick={() => deleteCompany()}
        primaryBgColor={acterioTheme.warningRed}
        primaryBgHoverColor={acterioTheme.warningPlaceholder}
        secondaryText='Cancel'
        secondaryOnClick={() => setShowDelete(false)}
        headerText={'Are you sure you want to delete this company?'}>
        <>
          <Box>
            This action is irreversible and you will NOT be able to recover the
            company or any of its related data.
          </Box>
        </>
      </InformationModal>
    );
  }

  function renderEditButton() {
    return (
      <Box sx={{ width: '3rem' }}>
        {canEditCompanyProfile && (
          <MoreOptionsOverlay
            sx={{
              backgroundColor: 'inherit',
              m: '0rem 1rem 0rem 0.5rem',
              p: '0rem',
              boxShadow: theme.shadows[1],
            }}
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}>
            <MoreOptionsButtons buttonList={buttonList} />
          </MoreOptionsOverlay>
        )}
      </Box>
    );
  }

  function renderHeaderContainer() {
    return (
      <>
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            alignItems: 'start',
            flexWrap: 'noWrap',
          }}>
          <FlexBox
            sx={{
              alignItems: 'end',
              flexWrap: 'wrap',
            }}>
            <Typography
              variant={'h5'}
              sx={{
                my: '0.5rem',
                pr: '1rem',
                width: '100%',
              }}>
              {name}
            </Typography>
            {renderOldTagGroups()}
            {renderTagGroup()}
          </FlexBox>
          <FlexBox
            sx={{
              alignItems: 'center',
              whiteSpace: 'wrap',
            }}>
            {renderCompanyTemplateTag()}
            {renderEditButton()}
          </FlexBox>
        </FlexBox>
        {renderDeleteModal()}
      </>
    );
  }

  function renderHeaderFields() {
    if (!companyProfile?.header) return null;

    return companyProfile.header.map((field: ICompanyField) => {
      if (
        field.type === CompanyProfileFieldType.TEXT &&
        field.text !== undefined &&
        field.text !== ''
      ) {
        return (
          <Box key={field.name + Math.random()} sx={{ pr: '1rem' }}>
            <Typography
              variant='body2'
              sx={{
                whiteSpace: 'wrap',
              }}>
              <FlexBox>
                <Tooltip title={field.name}>
                  <Box sx={{ pr: '0.5rem' }}>
                    <IconPicker
                      name={field.icon}
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </Box>
                </Tooltip>
                {field.text}
              </FlexBox>
            </Typography>
          </Box>
        );
      }
      return null;
    });
  }

  function renderDescription() {
    return (
      <Box>
        <Typography
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1.75rem',
            height: '3.5rem',
            mb: '-0.5rem',
          }}>
          {companyProfile?.description}
        </Typography>
      </Box>
    );
  }

  return (
    <Card
      sx={{
        width: '100%',
        mb: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '12px',
        backgroundColor: `${primary.pureWhite}`,
        border: `1px solid ${primary.natural6}`,
      }}>
      {renderCardMedia()}
      <CardContent sx={{ pr: '0rem' }}>
        {isDeleteLoading ? (
          <Loading height={120} />
        ) : (
          <>
            {renderHeaderContainer()}
            <FlexBox
              sx={{
                width: '100%',
                mt: '0.5rem',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}>
              <FlexBox
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  rowGap: '1rem',
                }}>
                {renderDescription()}
                <FlexBox sx={{ flexWrap: 'wrap-reverse' }}>
                  {renderHeaderFields()}
                </FlexBox>
              </FlexBox>
              <Box sx={{ ml: '1rem', pl: '1rem', mr: '3rem' }}>
                <CompanyContact email={email} telephone={telephone} />
              </Box>
            </FlexBox>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyHeader;
