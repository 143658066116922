import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import { AddToDrive } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { IResource } from 'utils/models';
import { getEcosystemInfo } from 'utils/storage';
import { MeetingRoom, MenuBook } from '@mui/icons-material';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  infoCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resourceCard: {
    minHeight: '120px',
    boxShadow: theme.shadows[1],
    padding: '1rem',
    display: 'flex',
    borderRadius: '6px',
    cursor: 'pointer',
    background: '#ffffff',
  },

  info: {
    display: 'flex',
    alignItems: 'center',
  },

  resourceTitle: {
    color: '#555555',
    fontWeight: '700 !important',
  },
  resourceLink: {
    textDecoration: 'inherit',
    color: 'inherit',
    width: '100%',
  },

  resourceIcon: {
    height: '38px!important',
    width: 'auto!important',
    marginLeft: '20px',
    marginRight: (props: any) => (props.isMobile ? '1rem' : '65px'),
    fill: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  resourceContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

interface OwnProps {
  resource: IResource;
  setResource: (resource: IResource | null) => any;
}

const ResourceCard: FunctionComponent<OwnProps> = (props) => {
  const { resource, setResource } = props;
  const { name, icon, url } = resource;
  const { isMobile } = useMobileContext();
  const classes = useStyles({ isMobile });

  function isEmailCheck(input: string): boolean {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(input);
  }

  function assignIcon() {
    if (icon === 'book') {
      return <MenuBook className={classes.resourceIcon} />;
    }
    if (icon === 'google-drive') {
      return <AddToDrive className={classes.resourceIcon} />;
    }
    if (icon === 'hyperlink') {
      return <LinkIcon className={classes.resourceIcon} />;
    }
    if (icon === 'folder') {
      return <FolderIcon className={classes.resourceIcon} />;
    }
    if (icon === 'email-link') {
      return <MeetingRoom className={classes.resourceIcon} />;
    }

    return <MenuBook className={classes.resourceIcon} />;
  }

  return (
    <Fade in={true} timeout={1000}>
      <div className={classes.resourceContainer}>
        <a
          href={isEmailCheck(url) ? `mailto:${url}` : url}
          target='_blank'
          className={classes.resourceLink}>
          <div className={classes.resourceCard}>
            <div className={classes.infoCont}>
              <div className={classes.info}>
                {assignIcon()}
                <Typography variant={'h5'} className={classes.resourceTitle}>
                  {name}
                </Typography>
              </div>
            </div>
          </div>
        </a>
        {getEcosystemInfo().accessLevel.canAddResources && (
          <DeleteIcon
            className={classes.resourceIcon}
            onClick={() => setResource(resource)}
          />
        )}
      </div>
    </Fade>
  );
};

export default ResourceCard;
