import React, { useState } from 'react';
import { Select, MenuItem, Checkbox, Radio, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CommonButton, { ButtonSize } from '../common/CommonButton';

export interface ISelectDropdown<T = any> {
  value: string | string[] | undefined;
  placeholder?: string;
  onChange: (value: T | T[]) => void;
  options: string[] | number[];
  multiple?: boolean;
  getOptionLabel?: (option: T) => string;
  backgroundColor?: string;
  disabled?: boolean;
  height?: number | string;
  width?: number | string;
  filterButton?: boolean;
  isClearButtonOff?: boolean;
  maxOptionsDisplayed?: number;
  isEmpty?: boolean;
  sx?: any;
  menuStyles?: any;
  forwardedRef?: React.Ref<HTMLDivElement>;
}

const SelectDropdown: React.FC<ISelectDropdown> = ({
  value,
  placeholder = 'Select value...',
  onChange,
  options,
  multiple = false,
  getOptionLabel,
  backgroundColor,
  disabled,
  height,
  width,
  filterButton = false,
  isClearButtonOff = false,
  maxOptionsDisplayed,
  isEmpty = false,
  sx,
  menuStyles,
  forwardedRef,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function renderOptions() {
    return options.map((option, index) => (
      <MenuItem key={`select-option-${index}`} value={option}>
        {multiple ? (
          <Checkbox
            checked={Array.isArray(value) && value.includes(option as string)}
          />
        ) : (
          <Radio checked={value === option} />
        )}
        {getOptionLabel ? getOptionLabel(option) : option}
      </MenuItem>
    ));
  }

  function renderValueSelected(selected: any | any[]) {
    if (Array.isArray(selected)) {
      if (selected.length === 0 || isEmpty) return placeholder;
      if (maxOptionsDisplayed) {
        return (
          selected
            .slice(0, maxOptionsDisplayed)
            .map((item) => (getOptionLabel ? getOptionLabel(item) : item))
            .join(', ') + (selected.length > maxOptionsDisplayed ? '...' : '')
        );
      }
      return selected
        .map((item) => (getOptionLabel ? getOptionLabel(item) : item))
        .join(', ');
    }
    return selected
      ? getOptionLabel
        ? getOptionLabel(selected)
        : selected
      : placeholder;
  }

  return (
    <Select
      ref={forwardedRef}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple={multiple}
      disabled={disabled}
      displayEmpty
      value={value ?? (multiple ? [] : '')}
      onChange={(event) => {
        if (multiple) {
          onChange(event.target.value as any[]);
        } else {
          onChange(event.target.value as any);
        }
      }}
      renderValue={renderValueSelected}
      MenuProps={{
        sx: {
          maxHeight: '70vh',
          ...menuStyles,
        },
      }}
      sx={{
        width: width ?? '50%',
        minWidth: width ? 'unset' : '12rem',
        height: height ?? '2rem',
        minHeight: height ? 'unset' : '32px',
        bgcolor: backgroundColor ?? theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: theme.shadows[2],
        transition: 'all 0.3s ease-in-out',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: 'inset 0 0 0 0.063rem transparent',
          transition: 'box-shadow .3s ease',
        },
        '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            boxShadow: `inset 0 0 0 0.063rem ${theme.palette.primary.main}`,
          },
        '& .MuiSvgIcon-root': {
          transition: 'all 0.3s ease-in-out',
        },
        ...sx,
      }}>
      {renderOptions()}

      {((multiple && (value as any[])?.length > 0) || (!multiple && value)) && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            my: '0.5rem',
          }}>
          {!isClearButtonOff && (
            <CommonButton
              text='Clear selection'
              onClick={() => onChange(multiple ? [] : undefined)}
              bgcolor={theme.palette.primary.light}
              color={theme.palette.primary.dark}
              size={ButtonSize.SMALL}
              sx={{ width: '96%', margin: 'auto' }}
            />
          )}
        </Box>
      )}

      {filterButton && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            mb: '0.5rem',
          }}>
          <CommonButton
            text={
              value && (Array.isArray(value) ? value.length > 0 : value)
                ? 'Filter'
                : 'Close'
            }
            onClick={() => setOpen(false)}
            bgcolor={theme.palette.primary.dark}
            size={ButtonSize.SMALL}
            sx={{ width: '96%', margin: 'auto' }}
          />
        </Box>
      )}
    </Select>
  );
};

export default SelectDropdown;
